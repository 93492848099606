@font-face {
  font-family: "Jost";
  src: local("Jost"),
  url("./fonts/Jost-Regular.woff2") format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: "Jost";
  src: local("Jost"),
  url("./fonts/Jost-Bold.woff2") format("woff2");
  font-weight: bold;
}
@font-face {
  font-family: 'Furore';
  font-style: normal;
  font-weight: normal;
  src: local('Furore'), url('./fonts/Furore.woff') format('woff');
}

@font-face {
  font-family: 'Aubrey Pro';
  font-style: normal;
  font-weight: 400;
  src: local('AubreyPro'), url('./fonts/AubreyPro.otf') format('opentype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat-Medium'), url('./fonts/Montserrat-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat-Regular'), url('./fonts/Montserrat-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  src: local('Montserrat-Bold'), url('./fonts/Montserrat-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Micra';
  font-style: normal;
  font-weight: normal;
  src: local('Micra-Bold'), url('./fonts/Micra-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Stem';
  font-style: normal;
  font-weight: normal;
  src: local('Stem'), url('./fonts/Stem.woff2') format('woff2');
}

body {
  margin: 0;
  font-family: Jost, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #121d1a;*/
  color: #c19c7d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.defaultUl{
  list-style: url("./components/index/images/ui-bullet.png");
}