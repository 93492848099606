.input-big{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #000000;
    background: #DADADA;
    border: 2px solid #9D9D9D;
    border-radius: 3px 17px;
    width: 290px;
    height: 52px;
    padding: 16px 17px;
    outline: none;
}
.input-big.error{
    border-color: darkred !important;
}
.input-big:hover{
    border: solid 2px  #c7a265;
}

.input-big:active{
    border: solid 2px  #c7a265;
}

.btn-form{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.19), rgba(0, 0, 0, 0.19)), #EFB365;
    width: 290px;
    height: 52px;
    border-radius: 3px 17px;
    border: transparent;
    cursor: pointer;
}
.planshet-form{
    background: url("./images/tablet.webp") center;
    max-width: 658px;
    width: 100%;
    max-height: 573px;
    padding: 128px 105px;
}


.title-form-text{
    font-family: 'Aubrey Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.135em;
    color: #FFFFFF;
}

.description-form-text{
    font-family: 'Aubrey Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.08em;
    color: #FFFFFF;
}
.col-request-form{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 60px;
    width: auto;
    height: auto;
    background: rgba(255, 255, 255, 0.26);
    border-radius: 17px;
}


.col-request-footer{
    padding: 30px 50px;
    width: auto;
    height: auto;
    background: rgb(0 0 0 / 49%);
    border-radius: 17px;
    border: solid 2px #c7a265;
}