.lite_btn{
    font-family: Montserrat, Regular, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #000000;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.19), rgba(0, 0, 0, 0.19)), rgba(255, 255, 255, 0.75);
    border-radius: 3px 16px;
    display: inline-block;
    border: solid 1px rgba(0, 0, 0, 0.57);
    padding: 18px 26px;
    width: auto;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    text-align: center !important;
}

.lite_btn:hover{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.19), rgba(0, 0, 0, 0.19)), #EFB365;
    border: solid 1px rgba(0, 0, 0, 0.57);
    color: #FFFFFF;
}

.lite_btn:active{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.19), rgba(0, 0, 0, 0.19)), #EFB365;
    border: solid 1px rgba(0, 0, 0, 0.57);
    color: #FFFFFF;
}

.lite_btn_brown{
    font-family: Montserrat, Regular, serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.19), rgba(0, 0, 0, 0.19)), #EFB365;
    border: solid 1px rgba(0, 0, 0, 0.57);
    border-radius: 3px 16px;
    display: inline-block;
    padding: 16px 21px;
    width: auto;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    text-align: center !important;
}

.lite_btn_brown:hover{
    color: #ffffff;
}

.lite_btn_brown:active{
    color: #ffffff;
}